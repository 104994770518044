import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../../layout";
import { BlogEntry, DesktopRightImage, Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 className="px-2 text-center">The targeted customers</h1>
    <h3 className="text-center text-lg">
  Some of the customers that benefit from our solutions
    </h3>
    <br />
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The typical customers are destination management organizations (DMOs),
  accommodation, recreation facilities, tour operators, rental services, sports
  shops, and the end users. There are also other types of customers such as
  sport organizations, real estate agents and developers of other digital
  solutions.
    </div>
    <Image src="/webapp/earnmoney.jpg" mdxType="Image" />
    <h4>{`Destination Management Organizations (DMOs)`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  DMOs are the companies typically responsible for marketing a destination or
  tourist region. A destination needs to be able to manage their available tours
  and trails and efficiently make the information accessible to their guests and
  visitors.
    </div>
    <br />
    <h4>{`Accommodations`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Another large group of customers are businesses like resorts, hotels, B&B,
  lodges and campsites. One just needs to land one deal with one large chain of
  hotels to make a big impact on the company revenue. Many others will then
  follow.
    </div>
    <br />
    <h4>{`Recreation facilities`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  These are typically ski resorts, bike parks, and other types of recreation
  facilities.
    </div>
    <br />
    <h4>{`Tour operators`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  A tour operator typically pays to be visible and have their tours and trails
  available to their guests on their website.
    </div>
    <br />
    <h4>{`Rental services`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  A company renting out bikes might have a QR code stuck on each bike with
  access to local tours suitable for the specific bike being rented out.
    </div>
    <br />
    <h4>{`Sports shops`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  A high quality sports shop might have a solution with a physical map hanging
  on the wall of their shop. On the map there is a QR code their shoppers can
  scan for mountain bike tours in the area. The shop can also visible on the map
  in the trailguide mountain biking app and have their own landing page in the
  app.
    </div>
    <br />
    <h4>{`Mountain bikers`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  End users of our trailguide.net mountain biking app subscribe to Trailguide
  Premium, a solution that gives access to extra features.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      